import React from "react"
import Link from "./link"
import { htmlDecode } from "../Utils"
import Marquee from "react-fast-marquee";

const Banner = ({
  data=null,
  term=null,
  termSlug=null,
  type=null,
  title=null,
  featuredImage=null
}) => {
  let bannerClass = 'banner'
  let bannerBgClass = 'banner-bg-image col-xs-12 ph0'
  let jsBannerClass = 'banner-bg-image col-xs-12 ph0 large video-bg'
  let blueOverlayClass = ''
  let overlayClass = 'banner-overlay'
  let bannerInsideClass = 'banner-inside'
  let bannerTextClass = 'col-xs-12 banner-text'

  if (term) {
    bannerClass += ` ${term}`
    bannerInsideClass += ` ${term}`
    bannerTextClass += ` ${term}`
  }
  if (data.bannerSize === 'large' && data.bannerImageOrVideo === 'fullScreenVideo') {
    bannerClass += ' banner-video'
  }
  if (data.bannerSize === 'medium' && data.bannerIncorporateVideo) {
    bannerClass += ' vid-wrap'
  }
  if (data.bannerSize === 'small') {
    bannerBgClass += ' small'
  }
  if (term === 'case-study' || data.bannerSize === 'medium') {
    bannerBgClass += ' medium'
  }
  if (data.bannerSize === 'large') {
    bannerBgClass += ' large'
  }
  if (data.bannerOverlay === 'blueOverlay') {
    bannerBgClass += ' gradient-map'
    blueOverlayClass = 'banner-overlay blue-overlay'
    overlayClass += ' blue-overlay'
    bannerTextClass += ' blue-overlay'
  }
  if (data.bannerOverlay === 'blueClearGradientOverlay') {
    jsBannerClass += ' diag-gradient'
    overlayClass += ' diag-gradient'
    bannerTextClass += ' blue-overlay diag-gradient'
  }
  if (data.bannerOverlay === 'blueClearDiagonalOverlay') {
    jsBannerClass += ' diag-gradient'
    overlayClass += ' diag-gradient'
    bannerTextClass += ' blue-overlay diag-gradient'
  }
  if (data.bannerOverlay === 'blueClearVerticalOverlay') {
    jsBannerClass += ' vert-gradient'
    overlayClass += ' vert-gradient'
    bannerTextClass += ' blue-overlay vert-gradient'
  }
  if (data.bannerHaveMarquee) {
    bannerInsideClass += ' marquis'
  }
  if (data.bannerOverlay === 'noOverlay') {
    bannerInsideClass += ' no-overlay'
  }
  if (data.bannerIncorporateVideo) {
    bannerInsideClass += ' jcfs aic vid-inner'
  } else {
    if (((data.textBlockHorizontalAlignment === 'left' || data.textBlockHorizontalAlignment === 'center' || data.textBlockHorizontalAlignment === 'right') && !data.fullWidthTextBlock) || ((data.textBlockHorizontalAlignment === 'left' || data.textBlockHorizontalAlignment === 'right') && data.fullWidthTextBlock)) {
      bannerTextClass += ' col-sm-10 col-md-8'
    } else if (data.textBlockHorizontalAlignment === 'center' && data.fullWidthTextBlock) {
      bannerTextClass += ' col-sm-10'
    }

    if (data.textBlockVerticalAlignment === 'top') {
      bannerInsideClass += ' aifs'
    } else if (data.textBlockVerticalAlignment === 'center') {
      bannerInsideClass += ' aic'
    } else if (data.textBlockVerticalAlignment === 'bottom') {
      bannerInsideClass += ' aife'
    }

    if (data.textBlockHorizontalAlignment === 'left') {
      bannerInsideClass += ' jcfs'
      bannerTextClass += ' m-left'
    } else if (data.textBlockHorizontalAlignment === 'center') {
      bannerInsideClass += ' jcc'
      bannerTextClass += ' m-center'
    } else if (data.textBlockHorizontalAlignment === 'right') {
      bannerInsideClass += ' jcfe'
      bannerTextClass += ' m-right'
    }

    if (data.textBlockContentAlignment === 'left') {
      bannerTextClass += ' text-left'
    } else if (data.textBlockContentAlignment === 'center') {
      bannerTextClass += ' text-center'
    } else if (data.textBlockContentAlignment === 'right') {
      bannerTextClass += ' text-right'
    }

    if (data.bannerSize === 'large') {
      bannerTextClass += ' large'
    }
  }

  const bannerImage = data.bannerImage ? data.bannerImage.url : '';
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';
  const bannerImageUrl = bannerImage.includes(cdnUrl) ? bannerImage : cdnUrl + bannerImage;


  return (
    <section className="banner-wrap">
      <div id="banner" className={bannerClass}>
        {data.bannerSize !== 'noBanner' && (
          <>
            {(data.bannerImageOrVideo === 'bannerImage' ||
              data.bannerImageOrVideo !== 'fullScreenVideo') &&
              type === 'post' && (
                <div
                  className={bannerBgClass}
                  style={{
                    backgroundImage: `url("${featuredImage}")`,
                    opacity: 1,
                  }}
                >
                  &nbsp;
                </div>
              )}

            {(data.bannerImageOrVideo === 'bannerImage' ||
              data.bannerImageOrVideo !== 'fullScreenVideo') &&
              type !== 'post' && (
                <div
                  className={bannerBgClass}
                  style={{
                    backgroundImage: `url("${bannerImageUrl}")`,
                    opacity: 1,
                  }}
                >
                  &nbsp;
                </div>
              )}

            {data.bannerSize === 'large' &&
              data.bannerImageOrVideo === 'fullScreenVideo' && (
                <div id="js-banner-bg" className={jsBannerClass}>
                  <div className={blueOverlayClass} />
                  <div className="loading-img">
                    <img
                      src={data.fullScreenVideoThumbnail.url}
                      alt={data.fullScreenVideoThumbnail?.alternativeText}
                    />
                  </div>
                </div>
              )}

            {(data.bannerImageOrVideo === 'bannerImage' ||
              data.bannerImageOrVideo !== 'fullScreenVideo') && (
              <div className={overlayClass} />
            )}

            <div className={bannerInsideClass}>
              <div className={bannerTextClass}>
                {term && (
                  <Link
                    className={`cat-btn mb3 dib ${
                      data.textColor === 'white' ||
                      data.bannerOverlay !== 'noOverlay'
                        ? 'bcw tcw'
                        : 'tc4 tcw'
                    }`}
                    href={`/blog/category/${termSlug}`}
                  >
                    {term}
                  </Link>
                )}

                {type === 'resource' && <></>}

                {type === 'event' && <></>}

                {type !== 'event' && type !== 'resource' && (
                  <>
                    {title && (
                      <h1
                        className={`${
                          data?.textColor === 'white' ? 'tcw' : ''
                        }`}
                      >
                        {htmlDecode(title)}
                      </h1>
                    )}
                    {!title && data.bannerTitle && (
                      <h1
                        className={`${
                          data?.textColor === 'white' ? 'tcw' : ''
                        }`}
                      >
                        {htmlDecode(data.bannerTitle)}
                      </h1>
                    )}
                  </>
                )}

                {data.bannerCaption && (
                  <h2 className={`${data?.textColor === 'white' ? 'tcw' : ''}`}>
                    {htmlDecode(data.bannerCaption)}
                  </h2>
                )}
                {data.bannerCtaButtons && (
                  <div className="cta-buttons">
                    {data.bannerCtaButtons.map(button => (
                      <Link
                        href={button.link}
                        className="button"
                        title={button.text}
                      >
                        {button.text}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {data.bannerHaveMarquee && (
              <div className="logo-ticker-bar">
                <div className="col-xs-12">
                  <Marquee
                    className="logo-ticker"
                    gradient="false"
                    gradientWidth="0"
                  >
                    {data.logoMarquee &&
                      data.logoMarquee.map(item => (
                        <img
                          src={item.logo.url.includes(cdnUrl) ? item.logo.url : `${cdnUrl}${item.logo.url}`}
                          className="logo-ticker-img"
                          alt={item.logo?.alternativeText}
                        />
                      ))}
                  </Marquee>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default Banner
